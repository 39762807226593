<template>
  <div>
    <v-layout wrap justify-center class="mainfont">
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#283e51"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs11 lg3 pt-7>
            <span style="font-size: 25px; font-weight: bolder"
              >SUPERINTENDENT</span
            >
          </v-flex>
          <v-flex xs12 lg8 pr-3 pt-7 text-right>
            <v-layout wrap justify-end>
              <v-flex xs3 lg1>
                <v-btn
                  block
                  @click="dialog1 = true"
                  class="bgcustom mainfont text-center"
                >
                  <span class="mainfont" style="color: white"> Add </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog1" max-width="500px">
      <v-card>
        <v-card-title class="text-center">
          <v-layout wrap>
            <v-flex xs12 text-center>
              <span class="mainfont"> Add </span>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-text-field
            class="mainfont"
            v-model="username"
            label="Username"
            outlined
            :rules="usernameRules"
          ></v-text-field>
          <v-text-field
            class="mainfont"
            v-model="officeName"
            label="Office Name"
            outlined
            :rules="officenameRules"
          ></v-text-field>
          <v-text-field
            class="mainfont inputPrice"
            v-model="mobNumber"
            label="Mobile Number"
            type="number"
            outlined
            :rules="mobNumberRules"
          ></v-text-field>
          <v-text-field
            class="mainfont"
            v-model="password"
            label="Password"
            outlined
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            class="mainfont"
            v-model="confirmPassword"
            label="Confirm Password"
            outlined
            c:\Users\Abhishek Nair A\Downloads\ajuprofile.jpg
          ></v-text-field>
          <v-text-field
            class="mainfont"
            v-model="emailId"
            label="Email ID"
            outlined
            :rules="emailRules"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-layout wrap justify-center pb-4>
            <v-flex xs3 pr-2>
              <v-btn
                color="red"
                block
                @click="dialog1 = false"
                class="mainfont text-center"
              >
                <span style="color: white"> Cancel </span>
              </v-btn>
            </v-flex>
            <v-flex xs3 pl-2>
              <v-btn
                block
                @click="addClerk"
                class="mainfont bgcustom text-center"
              >
                <span style="color: white"> Add </span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      officeName: "",
      mobNumber: "",
      password: "",
      confirmPassword: "",
      emailId: "",
      dialog1: false,
      appLoading: false,
      msg: null,
      showSnackBar: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    usernameRules() {
            return [
                (v) => !!v || "Username is required",
                (v) => /^[A-Za-z\s]+$/.test(v) || "Username must contain only alphabets and spaces"
            ];
            },
            officenameRules() {
            return [
                (v) => !!v || "Office Name is required",
                (v) => /^[A-Za-z\s]+$/.test(v) || "Office Name must contain only alphabets and spaces"
            ];
            },
    mobNumberRules() {
      return [
        (value) => !!value || "Mobile number is required",
        (value) =>
          (value && /^[0-9]{10}$/.test(value)) ||
          "Mobile number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (value) => !!value || "Email is required",
        (value) => (value && /.+@.+\..+/.test(value)) || "Email must be valid",
      ];
    },
  },
  methods: {
    addClerk() {
      let validationErrors = [];

      if (!this.username) {
        validationErrors.push("Please enter a username.");
      }
      if (!this.officeName) {
        validationErrors.push("Please enter an office name.");
      }
      if (!this.mobNumber) {
        validationErrors.push("Please enter a mobile number.");
      }
      if (!this.password) {
        validationErrors.push("Please enter a password.");
      }
      if (!this.confirmPassword) {
        validationErrors.push("Please enter the confirm password.");
      }
      if (this.password !== this.confirmPassword) {
        validationErrors.push("Password and Confirm Password do not match.");
      }
      if (!this.emailId) {
        validationErrors.push("Please enter an email ID.");
      }

      if (validationErrors.length > 0) {
        this.msg = validationErrors.join(" ");
        this.showSnackBar = true;
        return;
      }
      axios({
        method: "POST",
        url: "/add/superintendent",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          username: this.username,
          officeName: this.officeName,
          mobNumber: this.mobNumber,
          password: this.password,
          confirmPassword: this.confirmPassword,
          emailId: this.emailId,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog1 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  
<style>
  .no-spinners {
    /* Remove the up and down arrows */
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }
  
  .inputPrice input::-webkit-outer-spin-button,
  .inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  </style>